<template>
	<div
		class="feedback"
		:class="{'loading': loading}"
	>
		<div class="feedback__title">Обратная связь</div>
		<form class="feedback__form">
			<div class="feedback__name">
				<label class="input-wrapper">
					<div class="send-sms-to-phone__input-wrapper">
						<input
							class="send-sms-to-phone__phone-input"
							:type="formFields.name.type"
							v-model="formFields.name.value"
							id="feedback-name"
							@change="clearError('name')"
						/>
						<label
							class="send-sms-to-phone__phone-label"
							:class="{'send-sms-to-phone__phone-label_active': formFields.name.value !== ''}"
							for="feedback-name"
						>Ваше имя
						</label>
						<div
							class="send-sms-to-phone__clear"
							:class="{'send-sms-to-phone__clear_hidden': formFields.name.value === ''}"
							@click="clearInput('name')"
						/>
					</div>
					<span
						class="input-wrapper__label-error"
						:style="{'display': formFields.name.error ? 'block' : 'none'}"
						v-html="formFields.name.error"
					/>
				</label>
			</div>
			<div class="feedback__contacts">
				<label class="input-wrapper">
					<div class="send-sms-to-phone__input-wrapper">
						<PhoneInput
							type="tel"
							v-model="formFields.phone.value"
							:valuePhone="formFields.phone.value"
							name="phone"
							class="send-sms-to-phone__phone-input"
							id="feedback-phone"
							@change="clearError('phone')"
						/>
						<label
							for="feedback-phone"
							class="send-sms-to-phone__phone-label"
							:class="{'send-sms-to-phone__phone-label_active': formFields.phone.value !== ''}"
						>Телефон</label>
						<div
							class="send-sms-to-phone__clear"
							:class="{'send-sms-to-phone__clear_hidden': formFields.phone.value === ''}"
							@click="clearInput('phone')"
						/>
					</div>
					<span
						class="input-wrapper__label-error"
						:style="{'display': formFields.phone.error ? 'block' : 'none'}"
						v-html="formFields.phone.error"
					/>
				</label>
				<label class="input-wrapper">
					<div class="send-sms-to-phone__input-wrapper">
						<input
							class="send-sms-to-phone__phone-input"
							:type="formFields.email.type"
							v-model="formFields.email.value"
							name="email"
							id="feedback-email"
							@change="clearError('email')"
						>
						<label
							for="feedback-email"
							class="send-sms-to-phone__phone-label"
							:class="{'send-sms-to-phone__phone-label_active': formFields.email.value !== ''}"
						>Электронная почта
						</label>
						<div
							class="send-sms-to-phone__clear"
							:class="{'send-sms-to-phone__clear_hidden': formFields.email.value === ''}"
							@click="clearInput('email')"
						/>
					</div>
					<span
						class="input-wrapper__label-error"
						:style="{'display': formFields.email.error ? 'block' : 'none'}"
						v-html="formFields.email.error"
					/>
				</label>
			</div>
			<div class="feedback__message">
				<label class="input-wrapper">
					<div class="send-sms-to-phone__input-wrapper">
            			<textarea
							class="send-sms-to-phone__phone-input"
							v-model="formFields.message.value"
							name="message"
							id="feedback-message"
							@change="clearError('message')"
						></textarea>
						<label
							for="feedback-message"
							class="send-sms-to-phone__phone-label"
							:class="{'send-sms-to-phone__phone-label_active': formFields.message.value !== ''}"
						>Текст сообщения
						</label>
						<div
							class="send-sms-to-phone__clear"
							:class="{'send-sms-to-phone__clear_hidden': formFields.message.value === ''}"
							@click="clearInput('message')"
						/>
					</div>
					<span
						class="input-wrapper__label-error"
						:style="{'display': formFields.message.error ? 'block' : 'none'}"
						v-html="formFields.message.error"
					/>
				</label>
			</div>
			<div class="feedback__bottom">
				<div class="feedback__info">Заполняя данную форму и нажимая кнопку «Отправить» я даю свое согласие на
					обработку моих персональных данных, в соответствии с Федеральным законом от 27.07.2006 года №152-Ф3
					«О персональных данных», на условиях и для целей, определенных в Политике конфиденциальности
				</div>
				<button
					class="button button_red feedback__btn"
					@click.prevent="sendForm"
				>Отправить
				</button>
			</div>
		</form>
	</div>
</template>

<script>
import qs from 'qs';
import Validation from '@/mixins/validator.js';
import PhoneInput from '@/components/form/PhoneInput.vue';
import axios from "axios";

export default {
	components: {PhoneInput},
	mixins: [Validation],
	/**
	 * Глобальные переменные страницы
	 */
	data()
	{
		return {
			formFields:
				{
					name: {value: '', type: 'text', error: '', required: true},
					phone: {value: '', type: 'phone', error: '', required: true},
					email: {value: '', type: 'email', error: '', required: true},
					message: {value: '', type: 'text', error: '', required: true},
				},
			loading: false,
		}
	},
	methods:
		{
			async sendForm()
			{
				if (this.loading) return;

				try
				{
					this.loading = true;

					if (this.isNotValid(this.formFields)) return this.loading = false;

					const params = qs.stringify({
						name: this.formFields.name.value,
						phone: this.formFields.phone.value,
						email: this.formFields.email.value,
						message: this.formFields.message.value,
					});

					const {data} = await axios.post('/feedback/create/', params);

					if (!data.success) return;

					this.$emit('closePopup');

					for (let fieldError in data.errors)
					{
						if (typeof this.formFields[fieldError] != 'undefined')
							this.formFields[fieldError].error = data.errors[fieldError];
					}
				} catch (e)
				{
					throw new Error(e)
				} finally
				{
					this.loading = false;
				}
			},

			clearInput(inputName)
			{
				if (!inputName)
					return;

				this.formFields[inputName].value = "";
			},

			clearError(inputName)
			{
				if (!inputName)
					return;

				this.formFields[inputName].error = "";
			},
		}
}
</script>

<style lang="scss">

.feedback
{
	font-family: $mainFontNew;
	height: 535px;
	width: 738px;
	padding: 32px 40px;

	&.loading
	{
		opacity: 0.5;
	}

	.send-sms-to-phone__phone-input
	{
		width: 90%;
	}

	&__title
	{
		text-align: start;
		font-family: $mainFontBoldNew;
		font-size: 24px;
		line-height: 120%;
		color: $textColor;
		margin-bottom: 25px;
	}

	&__bottom
	{
		display: flex;
		justify-content: space-between;
	}

	&__info
	{
		font-family: $mainFontNew;
		font-size: 12px;
		color: $greyLightText;
		line-height: 14px;
		width: 398px;
	}

	&__btn
	{
		font-family: $mainFontBoldNew;
		font-weight: bold;
		width: 204px;
		border-radius: 8px;
		padding: 16px 0;
		height: fit-content;
	}

	&__name
	{
		width: 100%;
	}

	&__contacts
	{
		display: flex;
		justify-content: space-between;
		width: 100%;
		margin-bottom: 20px;
		height: 56px;

		.send-sms-to-phone__phone-input
		{
			width: 80%;
		}

		.input-wrapper
		{
			width: 313px;
			height: 56px;
		}
	}

	&__message
	{

		.send-sms-to-phone__input-wrapper
		{
			width: 100%;
			height: 179px;
			padding: 16px 20px;
			position: relative;
		}

		.send-sms-to-phone__phone-label
		{
			height: 14px;
			top: 20px;
		}

		textarea
		{
			outline: none;
			resize: none;
			width: 100%;
			height: 145px;
		}
	}
}

@media (max-width: 990px)
{
	.feedback
	{
		height: 537px;
		width: 680px;

		&__contacts
		{

			.input-wrapper
			{
				width: 284px;
			}
		}

		&__info
		{
			width: 372px;
			height: 70px;
			padding-right: 30px;
		}

	}
}

@media (max-width: 767px)
{
	.feedback
	{
		width: 100%;
		height: 100%;

		&__contacts
		{
			.input-wrapper:not(:last-child)
			{
				margin-right: 32px;
			}
		}
	}
}

@media (max-width: 576px)
{
	.feedback
	{
		width: 100%;

		.send-sms-to-phone__phone-input
		{
			width: 80%;
		}

		&__contacts
		{
			flex-direction: column;
			height: 100%;

			.input-wrapper
			{
				width: 100%;
			}
		}

		&__bottom
		{
			width: 100%;
			height: 100%;
			flex-direction: column;
		}

		&__info
		{
			width: 100%;
			height: 100%;
			padding-right: 0;
			margin-right: 0;
			margin-bottom: 20px;
		}

		&__btn
		{
			width: 100%;
		}

		&__message
		{
			textarea
			{
				width: 80%;
			}
		}
	}
}


</style>
