<template>
	<footer
		class="footer-wr"
		v-if="footerVisible"
	>
		<div class="container-base">
			<div
				class="footer"
				ref="footerRef"
			>
				<div
					class="footer-top"
					v-if="!isMobileApp"
				>
					<div class="footer__app footer__col">
						<span class="footer__app-header">Заказывайте в новом приложении <br> Вкусно и Быстро и получайте бонусы</span>
						<div class="footer__app-icons">
							<img
								src="~@/assets/img/googleplay.webp"
								alt="google play"
								class="footer__app-icon"
							>
							<img
								src="~@/assets/img/appstore.webp"
								alt="appstore"
								class="footer__app-icon"
							>
							<img
								src="~@/assets/img/appgallery.webp"
								alt="appgallery"
								class="footer__app-icon"
							>
							<!--							<a-->
							<!--								target="_blank"-->
							<!--								href="https://play.google.com/store/apps/details?id=com.vkusnoibistro.app&hl=ru&gl=US"-->
							<!--								class="footer__app-icon"-->
							<!--							>-->
							<!--							</a>-->
							<!--							<a-->
							<!--								target="_blank"-->
							<!--								href="https://apps.apple.com/ru/app/%D0%B2%D0%BA%D1%83%D1%81%D0%BD%D0%BE-%D0%B8-%D0%B1%D1%8B%D1%81%D1%82%D1%80%D0%BE/id1287404973"-->
							<!--								class="footer__app-icon"-->
							<!--							>-->
							<!--							</a>-->
							<!--							<a-->
							<!--								target="_blank"-->
							<!--								href="https://vkusnoibistro.drru.agconnect.link/nvb"-->
							<!--								class="footer__app-icon"-->
							<!--							>-->
							<!--							</a>-->
						</div>
					</div>
					<div class="footer__qr footer__col">
						<div class="footer__col footer__qr-code">
							<img
								src="~@/assets/img/qr-code.webp"
								alt="qr code"
								class="footer__qr-img"
							>
						</div>
						<div class="footer__col">
							<p class="footer__qr-text">Наведите камеру на QR-код,чтобы скачать</p>
						</div>
					</div>
				</div>
				<div class="footer-bottom">
					<div class="footer-bottom__top">
						<nav class="footer__col footer__nav">
							<ul>
								<li class="footer__nav-item">
									<a href="/about/">О сервисе</a>
								</li>
								<li class="footer__nav-item">
									<a href="/about/howto/">Как сделать заказ</a>
								</li>
								<li class="footer__nav-item">
									<a href="/about/bonus/">Скидки и баллы</a>
								</li>
								<li class="footer__nav-item">
									<a href="/about/delivery/">Оплата и доставка</a>
								</li>
							</ul>
							<ul>
								<li class="footer__nav-item">
									<a href="/news/">Новости и статьи</a>
								</li>
								<li class="footer__nav-item">
									<router-link to="/coverage">География покрытия</router-link>
								</li>
								<li class="footer__nav-item">
									<a href="/recipes/">Рецепты</a>
								</li>
								<li class="footer__nav-item">
									<a href="/policies/">Юридические документы</a>
								</li>
							</ul>
						</nav>
						<div class="footer__col footer__col_end">
							<div class="footer__col">
								<span class="footer__subheader">Бесплатный звонок по России</span>
								<div class="footer__phone-number">
									<a :href="'tel: '+returnFormatedPhone">
										{{ returnFormatedPhone }}
									</a>
								</div>
								<div class="footer__phone-number">
									<a href="tel:+78007754131">
										+7 (800) 775-41-31
									</a>
								</div>
								<div class="footer__make-call-wr">
									<div class="footer__make-call">Заказать звонок</div>
								</div>
							</div>
							<div class="footer__col footer__socials_md">
								<span class="footer__subheader">Мы в соц. медиа</span>
								<div class="footer__soc-icons">
									<a
										href="https://vk.com/vkusnoibistromsk"
										target="_blank"
									>
										<svg fill="transparent">
											<use xlink:href="#vk-icon"></use>
										</svg>
									</a>
									<a href="https://t.me/ViBtelegram_bot">
										<svg fill="transparent">
											<use xlink:href="#telegram-icon"></use>
										</svg>
									</a>
								</div>
							</div>
						</div>
					</div>
					<div class="footer-bottom__bottom">
						<div class="footer__col">
							<router-link
								to="/"
								class="footer__logo"
							>
								<svg
									x="0px"
									y="0px"
									width="200"
									height="60"
									viewBox="0 0 200 60"
								>
									<use xlink:href="#logo-white"></use>
								</svg>
							</router-link>
							<span class="footer__subheader">{{ (new Date()).getFullYear() }} Акционерное Общество «Вкусно и Быстро»,
                				ОГРН: 1177746198420, ИНН/КПП: 7743196356 / 774301001.
                				Любое использование материалов сайта без разрешения запрещено.
							</span>
						</div>
						<div class="footer__col footer__col_end footer__socials">
							<div class="footer__col">
								<span class="footer__subheader">Мы в соц. медиа</span>
								<div class="footer__soc-icons">
									<a
										href="https://vk.com/vkusnoibistromsk"
										target="_blank"
									>
										<svg fill="transparent">
											<use xlink:href="#vk-icon"></use>
										</svg>
									</a>
									<a href="https://t.me/ViBtelegram_bot">
										<svg fill="transparent">
											<use xlink:href="#telegram-icon"></use>
										</svg>
									</a>
								</div>
							</div>
						</div>
					</div>
					<div class="footer__section">
						<div class="footer__col">
            				<span class="footer__nav-item footer__nav-row">
								<a href="/policies/offer-nao-vkusnoibistro/">Правовая информация</a>
            				</span>
							<span
								class="footer__nav-item footer__nav-row"
								@click="showFeedbackPopup = true"
							>
              					Сообщить об ошибке на сайте
            				</span>
						</div>
						<div class="footer__col footer__col_end">
							<div class="footer__col">
								<span class="footer__subheader">Сделано — АО «Вкусно и Быстро»</span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<Popup :visible.sync="showNoRestaurantsPopup">
			<div class="footer__subscribe-popup-wrapper">
				<Subscribe @closePopup="showNoRestaurantsPopup = false"/>
			</div>
		</Popup>
		<Popup :visible.sync="showFeedbackPopup">
			<Feedback @closePopup="showFeedbackPopup = false"/>
		</Popup>
	</footer>
</template>
<script>
import NoRestaurants from '@/components/popup/NoRestaurants.vue'
import Subscribe from '@/components/form/Subscribe.vue'
import b24form from "@/mixins/b24form.js";
import Feedback from "../form/Feedback.vue";
import {useVkRetargeting} from "@/composables";

export default {
	components: {Feedback, NoRestaurants, Subscribe},
	mixins: [b24form],
	setup()
	{
		const {vkRetargetingViewContent} = useVkRetargeting();

		return {vkRetargetingViewContent}
	},
	/**
	 * data
	 */
	data()
	{
		return {
			showFeedbackPopup: false,
			showNoRestaurantsPopup: false,
			isMobileApp: null,
		}
	},
	computed:
		{
			/**
			 *  возвращает форматированнай телефон если подходит под регулярку или как пришел если нет
			 */
			returnFormatedPhone()
			{
				if (!this.$store.state.currentCityPhone)
					return ''

				let reg = /(^\d{1})(\d{3})(\d{3})(\d{1})(\d{3})/;
				let result = reg.exec(this.$store.state.currentCityPhone)


				if (typeof result === null)
					return result[1] + ' ' + result[2] + ' ' + result[3] + '-' + result[4] + '-' + result[5]
				else
					return this.$store.state.currentCityPhone

			},
			footerVisible()
			{
				return window.innerWidth >= 567
			}
		},
	methods:
		{
			/**
			 * Отркывает попап выбора адреса на карте
			 */
			showAddressMap()
			{
				this.showNoRestaurantsPopup = false;
			},
			handleScroll()
			{
				const footer = this.$refs.footerRef;

				if (!footer) return;

				const footerPositionTop = footer.getBoundingClientRect().top;
				const footerClientHeight = footer.clientHeight;
				const windowHeight = window.innerHeight;

				if (footerPositionTop + footerClientHeight <= windowHeight && footerPositionTop >= 0)
				{
					this.vkRetargetingViewContent();
				}
			},
		},
	mounted()
	{
		window.addEventListener('scroll', this.handleScroll);

		this.addB24FormOnBtn('footer__make-call-wr');
		const userAgent = window.navigator.userAgent;
		this.isMobileApp = userAgent.match(/mobileApp/i);
	},
	beforeDestroy()
	{
		window.removeEventListener('scroll', this.handleScroll);
	}
}
</script>
<style lang="scss">
.footer__subscribe-popup-wrapper
{
	width: 1200px;
	border-radius: 10px;
	padding: 53px 39px 39px 36px;
	border: 1px solid #EDEDED;
	box-sizing: border-box;
	letter-spacing: .1px;
}

.footer-wr
{
	background-color: $green;

	.footer__free-call
	{
		font-size: 16px;
	}
}

.footer
{
	color: #fff;
	font-family: $mainFontNew;
	padding: 60px 0px 72px;
	letter-spacing: 0.1px;

	&__col
	{
		flex: 1 1 50%;
		max-width: 50%;

		&_end
		{
			display: flex;
			justify-content: flex-end;
		}

		@media (max-width: 990px)
		{
			&_end
			{
				justify-content: flex-start;
				flex: 1 1 100%;
				max-width: 100%;
			}
		}
	}

	&__socials
	{
		&_md
		{
			display: none;
		}
	}

	&__soc-icons
	{
		& a
		{
			&:not(:last-child)
			{
				margin-right: 10px;
			}

			& svg
			{
				width: 45px;
				height: 45px;
			}
		}
	}

	&__logo
	{
		display: block;
		width: 200px;
		height: auto;
		margin-bottom: 32px;

		&-row
		{
			flex-direction: column;
		}

		@media (max-width: 990px)
		{
			margin-right: 80px;
		}
	}

	&__subheader
	{
		display: block;
		font-size: 14px;
		line-height: 24px;
		color: #fff;
		opacity: 0.6;
		margin-bottom: 8px;
	}

	&__phone
	{

		&-number
		{
			font-family: $mainFontBoldNew;
			font-size: 24px;
			font-weight: bold;
			margin-bottom: 24px;
			line-height: 32px;
			cursor: pointer;

			& a
			{
				color: #fff;
			}
		}
	}

	&__make
	{
		&-call
		{
			font-family: $mainFontBoldNew;
			display: block;
			border-radius: 8px;
			padding: 16px 0;
			color: #fff;
			font-size: 16px;
			font-weight: bold;
			line-height: 20px;
			max-width: 265px;
			width: 100%;
			text-align: center;
			border: 2px solid;
			transition: all .2s ease-in-out;
			cursor: pointer;

			&:hover
			{
				background: #fff;
				color: $green;
				border-color: $green;
			}
		}
	}

	&__nav
	{
		display: flex;
		justify-content: space-between;

		&-row
		{
			opacity: 1;
			transition: opacity .2s ease-in-out;
			cursor: pointer;

			&:hover
			{opacity: 0.8;}

			&:not(:last-child)
			{
				margin-bottom: 0;
				margin-right: 33px;
			}
		}

		&-item
		{
			&:not(:last-child)
			{
				margin-bottom: 16px;
			}

			a
			{
				color: #fff;
				font-size: 16px;
				line-height: 23px;
				opacity: 1;
				transition: opacity .2s ease-in-out;

				&:hover
				{
					opacity: 0.8;
				}
			}
		}

		@media (max-width: 990px)
		{
			& ul
			{
				flex: 1 1 50%;
			}

			&-item
			{
				font-size: 14px;
			}

			&-row
			{
				flex: 1 1 auto;
				max-width: 100%;

				& a
				{
					font-size: 12px;
					white-space: nowrap;
				}
			}

			flex: 1 1 100%;
			max-width: 100%;
		}
	}

	&__qr
	{
		display: flex;
		align-items: center;
		justify-content: flex-start;

		@media (max-width: 990px)
		{
			display: none;
		}

		&-text
		{
			font-size: 18px;
			line-height: 24px;
			color: #fff;
			font-family: $mainFontNew;
			max-width: 150px;
			flex: 1 1 50%;
		}

		&-img
		{
			width: 100%;
		}

		&-code
		{
			display: flex;
		}
	}

	&__app
	{
		&-header
		{
			display: block;
			font-family: $mainFontBoldNew;
			color: #fff;
			font-weight: bold;
			font-size: 24px;
			line-height: 28px;
			max-width: 475px;
			margin-bottom: 24px;
		}

		&-icon
		{
			&:not(:last-child)
			{
				margin-right: 16px;
			}
		}

		@media (max-width: 990px)
		{
			flex: 1 1 100%;
			max-width: 100%;
			display: flex;

			&-header
			{
				font-size: 16px;
				flex: 1 1 50%;
				max-width: calc(50% - 20px);
				margin-right: 20px;
			}

			&-icon
			{
				&:not(:last-child)
				{
					margin-right: 10px;
				}

				flex: 1 1 50%;
				max-width: 50%;

				& img
				{
					max-width: 105px;
				}
			}
		}
	}

	@media (max-width: 990px)
	{
		max-width: 100%;

		&__make
		{
			&-call
			{
				display: none;
			}
		}

		&__socials
		{
			display: none !important;

			&_md
			{
				display: flex !important;
				flex-direction: column;
			}
		}

		&__soc-icons
		{
			& a
			{
				& svg
				{
					width: 38px;
					height: 38px;
				}
			}
		}

		&__section
		{
			& .footer__col
			{
				flex: 1 1 auto;
				max-width: 100%;
			}
		}
	}
}

@media (max-width: 990px)
{
	.footer-bottom__top
	{
		& .footer
		{
			&__subheader, &__phone-number
			{
				white-space: nowrap;
			}

			&__subheader
			{
				font-size: 12px;
			}
		}
	}
}

.footer-top
{
	display: flex;
	border-bottom: 1px solid rgba(255, 255, 255, 0.4);
	justify-content: space-between;
	align-items: center;

	.footer__items
	{
		display: flex;
		flex-basis: 49%;
	}

	.footer__item
	{
		display: flex;
		flex-direction: column;
	}
}

.footer__item
{
	&_footer-contacts
	{
		flex-basis: 19%;

		& .footer-contacts
		{
			margin-bottom: 11px;
			display: flex;
		}
	}

	&:not(:last-child)
	{
		padding-right: 10px;
	}
}

.footer__item_services
{
	flex-basis: 51%;
}

.footer__item_social
{
	flex-basis: 62%;
	display: flex;
	flex-direction: column;
}

.footer__item_footer-contacts
{
	flex-basis: 38%;
}

.footer-contacts
{
	margin-bottom: 11px;
	display: flex;
}

.footer__headline
{
	font-style: normal;
	font-weight: bold;
	font-size: 20px;
	line-height: 100%;
	margin-bottom: 40px;
}

.footer-services__list
{
	display: flex;
	flex-direction: column;
	max-height: 200px;
	flex-wrap: wrap;
	width: 80%;

	& a
	{
		display: block;
		color: #f8f8f8;
		width: fit-content;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		line-height: 100%;
		margin-bottom: 25px;
		margin-right: 162px;

		&:not(:last-child)
		{
			margin-bottom: 25px;
		}
	}

}

.footer-socials
{
	margin-bottom: 36px;

	& a
	{
		display: inline-block;

		&:not(:last-child)
		{
			margin-right: 8px;
		}

		svg
		{
			opacity: 0.6;
			@include ease(.125s);
		}

		&:hover svg
		{
			opacity: 1;
		}
	}
}

.footer-text-us
{
	color: #fff;
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 100%;
}

.footer-contacts__icon
{
	padding-right: 10px;
}

.footer-contacts-number
{
	display: block;
	font-style: normal;
	font-weight: bold;
	font-size: 18px;
	line-height: 22px;
	color: #fff;
}

.footer-contacts-time
{
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 17px;
}

.footer__call-btn
{
	background-color: transparent;
	color: rgba(#fff, .8);
	border: 1px solid #727271;
	max-width: 180px;
	width: 100%;
	height: 40px;

	&:hover
	{
		color: #fff;
	}
}

.footer__section
{
	display: flex;
}

.footer-bottom
{
	display: flex;
	flex-direction: column;
	padding-top: 35px;

	&__top
	{
		display: flex;
		margin-bottom: 40px;
	}

	& .footer__item
	{
		display: flex;
		align-items: center;
	}

	&__bottom
	{
		display: flex;
		margin-bottom: 32px;
	}

	@media (max-width: 990px)
	{
		flex-wrap: wrap;

		&__top
		{
			flex-direction: column;
			max-width: 100%;
		}

		&__bottom
		{
			& .footer__col
			{
				flex-direction: row;
				flex: 1 1 auto;
				max-width: 100%;
				display: flex;
			}
		}
	}
}

.footer__item_logo
{
	flex-basis: 61%;
	font-size: 0;
	line-height: 0;

	svg
	{
		max-width: 170px;
		min-width: 170px;
	}
}

.footer__item .logo
{
	line-height: 0;
	margin-right: 145px;
}

.footer__item_agreement
{
	flex-basis: 20%;
}

.footer__item_author
{
	flex-basis: 19%;
}

.footer-copyright__headline
{
	display: block;
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 20px;
}

.footer-copyright__label
{
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 17px;
	opacity: .6;
	display: block;
}

.footer-agreement
{
	color: #fff;
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 120%;
}

.footer-author
{
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 20px;
	opacity: .4;
}

@media (max-width: 1544px)
{
	.footer-services__column:not(:last-child)
	{
		margin-right: 120px;
	}
	.footer-bottom .footer__item .logo
	{
		margin-right: 94px;
	}
	.footer__item_social
	{
		flex-basis: 50%;
	}
	.footer-bottom
	{
		display: flex;
		padding-top: 35px;
	}
	.footer__item_agreement
	{
		flex-basis: 20%;
	}
	.footer__item_author
	{
		flex-basis: 20%;
	}

}

@media (max-width: 1380px)
{
	.footer
	{
		padding: 20px 44px;

		&__nav-item a
		{
			font-size: 14px;
		}
	}
	.footer-top
	{
		padding-bottom: 0;

		.footer__item_footer-contacts
		{
			flex-direction: row;
			flex-basis: 66%;
			justify-content: space-between;
			margin-bottom: 50px;
			align-items: center;
		}
	}
	.footer__item_footer-contacts-top
	{
		margin-right: 20px;
		margin-left: 26px;
	}
	.footer__item_social
	{
		flex-basis: 34%;
	}
	.footer__call-btn
	{
		align-self: center;
		margin-top: 5px;
		margin-right: 57px;
	}
	.footer-services__list
	{
		max-height: 150px;

		a
		{
			width: 43%;
			margin-right: 0;
		}
	}
	.footer__item_services
	{
		margin-bottom: 35px;
	}
	.footer-bottom
	{
		padding-top: 30px;
		flex-wrap: wrap;
	}
	.footer__item_logo
	{
		flex-basis: 69%;
		justify-content: space-between;
	}
	.footer__item .logo
	{
		margin-right: 25px;
	}
	.footer__item_agreement
	{
		margin-top: -20px;
	}
	.footer__item_author
	{
		flex-basis: unset;
		margin-left: auto;
		margin-right: 42px;
		margin-top: 15px;
	}
	.footer-copyright
	{
		max-width: 329px;
		margin-top: 6px;
	}

}

@media (max-width: 990px)
{

	.footer
	{
		padding: 44px 0 76px;
		padding-top: 16px;

		&__app-icon img
		{
			height: 33px;
		}

		&__logo svg
		{
			width: 176px;
			height: 58px;
		}

		&-bottom__bottom
		{
			margin-bottom: 0;
		}
	}

}

@media (max-width: 767px)
{
	.footer
	{
		padding: 25px 0 65px;
	}
	.footer__headline
	{
		text-align: center;
		margin-bottom: 31px;
	}
	.footer__item
	{
		&:not(:last-child)
		{
			padding-right: 0;
			margin-right: 0;
			margin-bottom: 15px;
		}
	}
	.footer-services__list
	{
		flex-wrap: nowrap;
		max-height: unset;
		align-items: center;
		width: 100%;

		a
		{
			white-space: nowrap;
			width: fit-content;
			margin-bottom: 15px;
		}
	}
	.footer-top
	{
		padding-bottom: 50px;

		.footer__items
		{
			flex-direction: column;
		}

		.footer__item_footer-contacts
		{
			flex-basis: 100%;
			flex-direction: column;
			justify-content: unset;

			&:not(:last-child)
			{
				margin-bottom: 15px;
			}
		}
	}
	.footer__item_services
	{
		text-align: center;
	}
	.footer-contacts
	{
		width: fit-content;
		margin: 0 auto 6px;
	}
	.footer-socials
	{
		margin: 0 auto 17px;
	}
	.footer-text-us
	{
		text-align: center;
		margin-bottom: 49px;
	}
	.footer__call-btn
	{
		margin: 6px auto 0;
	}
	.footer-bottom
	{
		flex-direction: column;

		.footer__item
		{
			.logo
			{
				margin: 0 auto 20px;
			}
		}
	}
	.footer__item_logo
	{
		flex-direction: column;
		margin-bottom: 20px;
	}
	.footer-copyright
	{
		text-align: center;
	}
	.footer-copyright__headline
	{
		font-size: 14px;
		margin-bottom: 11px;
	}
	.footer-copyright__label
	{
		font-size: 12px;
	}
	.footer__item_agreement
	{
		width: fit-content;
		margin: 0 auto 35px !important;
	}
	.footer-agreement
	{
		text-align: center;
	}
	.footer__item_author
	{
		margin: 0;
	}
	.footer-author
	{
		white-space: nowrap;
		width: fit-content;
		margin: 0 auto;
	}
}
</style>
