export default {
	methods:
		{
			addB24FormOnBtn(className)
			{
				let b24FormBtnBlocks = document.getElementsByClassName(className);
				for (let i in b24FormBtnBlocks)
				{
					let firstElement = b24FormBtnBlocks[i].firstChild;
					if (firstElement && firstElement.tagName !== "SCRIPT")
					{
						let b24FormScript = document.createElement('script');
						b24FormScript.setAttribute('data-b24-form', 'click/5/tkawm3');
						b24FormScript.setAttribute('data-skip-moving', 'true');
						b24FormScript.innerHTML = "(function(w,d,u){var s=d.createElement('script');s.async=true;s.src=u+'?'+(Date.now()/180000|0);var h=d.getElementsByTagName('script')[0];h.parentNode.insertBefore(s,h);})(window,document,'https://cdn-ru.bitrix24.ru/b10182879/crm/form/loader_5.js');";
						b24FormBtnBlocks[i].insertBefore(b24FormScript, firstElement);
					}
				}
			},
		}
}