<template>
	<div
		class="bg-green"
		:class="{'landing-page': $route.path === '/'}"
	>
		<Header v-if="!isChatPage"/>
		<router-view/>
		<FooterNew v-if="!isMobile"/>
		<Sprite/>
		<MobileMenu v-click-outside="hideMobMenu"/>
	</div>
</template>

<script>
import '@/assets/scss/main.scss';

import Header from '@/components/layout/Header.vue'
import FooterNew from '@/components/layout/Footer.vue'
import Sprite from '@/components/Sprite.vue'
import MobileMenu from '@/components/block/MobileMenu.vue'
import {mapActions} from "vuex";

export default {
	name: 'MainScaffold',

	components: {Header, FooterNew, Sprite, MobileMenu},

	data: () => ({
		isMobile: !!process.env.VUE_APP_MOBILE,
		isChatPage: false,
	}),

	methods: {
		...mapActions({
			getBasketTypes: "getBasketTypes",
			getCart: "getCart",
		}),
		/**
		 * Считаем высоту сайта
		 */
		setWindowSize()
		{
			// First we get the viewport height and we multiple it by 1% to get a value for a vh unit
			let vh = window.innerHeight * 0.01;
			// Then we set the value in the --vh custom property to the root of the document
			document.documentElement.style.setProperty('--vh', `${vh}px`);
		},

		/**
		 * Пересчитываем высоту сайта
		 */
		windowResize()
		{
			var self = this
			var addEvent = function (object, type, callback)
			{
				if (object == null || typeof (object) == 'undefined')
					return;
				if (object.addEventListener)
					object.addEventListener(type, callback, false);
				else if (object.attachEvent)
					object.attachEvent("on" + type, callback);
				else
					object["on" + type] = callback;
			}

			/**
			 * Вызываем функцию windowSize
			 */
			addEvent(window, "resize", function (event)
			{
				if (addEvent)
					self.setWindowSize();
			})
		},

		/**
		 * скрывает мобильное меню по клику снаружи
		 */
		hideMobMenu(e)
		{
			let mobMenuIcon = document.querySelector('.header__nav-mobile_icon'),
				isIcon = false;

			if (!mobMenuIcon)
			{
				this.$store.commit('hideMobMenu');
				return;
			}

			for (let child of mobMenuIcon.children)
				if (e.target === child)
				{
					isIcon = true;
				}


			if (e.target === mobMenuIcon)
				isIcon = true;

			if (!isIcon || !this.$store.state.mobMenuState)
			{
				this.$store.commit('hideMobMenu');
			}


		},
	},
	beforeRouteUpdate(to, from, next)
	{
		if (to.path === '/chat')
		{
			this.isChatPage = true;
		} else
		{
			this.isChatPage = false;
		}

		next();
	},
	async mounted()
	{
		if (this.$route.path === "/chat") this.isChatPage = true;

		await this.getBasketTypes();
		await this.getCart();
	}
}
</script>

<style lang="scss">
.landing-page
{
	@media (max-width: 567px)
	{
		background: $green;
		height: 100vh;
		max-width: 100%;
		overflow: auto;
	}
}
</style>
