<template>
	<portal>
		<div
			class="pointed-modal"
			v-click-outside="closeAuthForm"
			:class="modalClass"
			:style="{
      top, left,
      position: fixed ? 'fixed' : 'absolute'
    }"
			ref="modal"
		>

			<svg
				xmlns="http://www.w3.org/2000/svg"
				width="120"
				height="37"
				fill="none"
				class="pointed-modal__pointer"
				:style="{left: `${leftShift}%`}"
			>
				<path
					fill="#fff"
					d="M62.7 1.2a3 3 0 0 0-4.4 0L52 8A20 20 0 0 1 37.5 14H11.2a11.3 11.3 0 0 0 0 22.5h97.5a11.3 11.3 0 0 0 0-22.5H83.5A20 20 0 0 1 69 7.9l-6.4-6.7Z"
				/>
			</svg>

			<div class="pointed-modal__content">
				<slot/>
			</div>

		</div>
	</portal>
</template>

<script>
export default {
	name: 'PointedModal',
	props: {
		point: String,
		leftAlign: {type: Number, default: 0},
		topAlign: {type: Number, default: 10},
		pointerLeftAlign: {type: Number, default: 60},
		fixed: {type: Boolean, default: false},
		modalClass: {type: Array, default: () => []}
	},

	data: () => ({
		left: 0,
		top: 0
	}),

	methods: {
		closeAuthForm()
		{
			this.$store.commit('setNavigationPopup', false);
		},
		locateWindow()
		{
			if (window.innerWidth <= 576) return;

			const el = document.querySelector(this.point);
			const rect = el.getBoundingClientRect();
			const bRect = document.body.getBoundingClientRect();

			this.top = `${rect.bottom - (this.fixed ? 0 : bRect.top + window.scrollY + el.clientHeight) + 15 + this.topAlign}px`;
			this.left = `${rect.left - bRect.left + window.scrollX - this.$refs.modal.clientWidth + el.clientWidth + this.leftAlign}px`;
		}
	},

	computed: {
		leftShift()
		{
			return window.innerWidth <= 576 ? 0 : this.pointerLeftAlign;
		}
	},

	mounted()
	{
		this.$nextTick(() =>
		{
			this.locateWindow();
		});
	}
}
</script>

<style lang="scss">
.pointed-modal
{

	position: absolute;
	z-index: 100;
	background: #fff;
	padding: 8px 24px;
	box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.11);
	border-radius: 16px;
	border: 1px solid #EDEDED;
	max-width: 400px;
	width: 100%;

	&__content
	{
		width: 100%;
	}

	&__pointer
	{
		top: -14px;
		right: 38px;
		position: absolute;
	}

	@media (max-width: 576px)
	{
		display: flex;
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		min-height: 100%;
		height: 100vh;
		max-width: unset;
		margin: 0;
		border-radius: 0;
		padding: 0;

		&__pointer
		{
			display: none;
		}
	}

}
</style>
