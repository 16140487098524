<template>
	<transition
		name="mobile-menu-anim"
		@enter="enter"
		@leave="leave"
		@afterLeave="afterLeave"
	>
		<div
			class="header__nav-mobile mobile-menu-anim"
			v-if="this.$store.state.mobMenuState"
		>
			<div class="mobile-menu-wr">
				<div
					class="mobile-menu__background"
					@click="closeMobileMenu"
				/>
				<div class="mobile-menu">
					<div class="mobile-menu__items">
						<div @click="closeMobileMenu">
							<router-link
								to="/"
								class="mobile-menu__logo"
							>
								<svg>
									<use xlink:href="#logo-icon"></use>
								</svg>
							</router-link>
						</div>
						<div
							class="mobile-menu__close"
							@click="closeMobileMenu"
						>
							<img
								src="~@/assets/img/close.svg"
								width="32"
								height="32"
							>
						</div>
						<div
							v-if="$store.state.user"
							class="header__bottom-photo-wrapper"
							@click="userMenuOpen"
						>
							<div class="header__bottom-photo">
								<img
									:src="user.PERSONAL_PHOTO"
									alt="user photo"
									class="header__bottom-user"
									v-if="user.PERSONAL_PHOTO"
								>
								<div
									class="header-main__bottom-user_placeholder header-main__bottom-user_placeholder_green"
									v-else
								>
									{{ firstNameLetter }}
								</div>
							</div>
							<div class="scores">
								<span class="scores__text">На счете баллов:</span>
								<div class="scores__token">
									<span class="scores__amount">{{ this.user.BALLS }}</span>
									<svg
										class="scores__icon"
										viewBox="0 0 12 12"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											fill-rule="evenodd"
											clip-rule="evenodd"
											d="M10.5 6C10.5 8.48528 8.48528 10.5 6 10.5C3.51472 10.5 1.5 8.48528 1.5 6C1.5 3.51472 3.51472 1.5 6 1.5C8.48528 1.5 10.5 3.51472 10.5 6ZM12 6C12 9.31371 9.31371 12 6 12C2.68629 12 0 9.31371 0 6C0 2.68629 2.68629 0 6 0C9.31371 0 12 2.68629 12 6ZM7.67782 3.27344H4.06982V9.00144H6.30182C6.65916 9.00144 6.98716 8.9321 7.28582 8.79344C7.58449 8.65477 7.81916 8.45477 7.98982 8.19344C8.16582 7.92677 8.25382 7.61477 8.25382 7.25744C8.25382 6.9001 8.16582 6.59077 7.98982 6.32944C7.81916 6.0681 7.58449 5.87077 7.28582 5.73744C6.99249 5.59877 6.66449 5.52944 6.30182 5.52944H5.14982V4.26544H7.67782V3.27344ZM6.94982 7.79344C6.80049 7.9321 6.60049 8.00144 6.34982 8.00144H5.14982V6.51344H6.34982C6.61116 6.51344 6.81382 6.58277 6.95782 6.72144C7.10182 6.8601 7.17382 7.03877 7.17382 7.25744C7.17382 7.47077 7.09916 7.64944 6.94982 7.79344Z"
											fill="white"
										/>
									</svg>
								</div>
							</div>
						</div>
						<div
							class="mobile-menu__item mobile-menu__item_sign-in mobile-menu__item_active mobile-menu__item_btn"
							@click="authOpen()"
							v-if="!$store.state.user"
						>
							Войти
						</div>
						<div class="mobile-menu__item mobile-menu-city">
							<div
								class="mobile-menu-city__static"
								@click="openCitySelect"
							>
								<span class="mobile-menu-city__row">
									<span class="mobile-menu-city__label">Ваш город:</span>
									<span class="mobile-menu-city__value">
										{{ $store.state.currentCity ? $store.state.currentCity : "" }}
									</span>
								</span>
							</div>
							<CitySelect
								:isActive="isActiveCitySelect"
								@closeCitySelect="closeCitySelect"
							/>
						</div>
						<a
							v-if="!isMobile"
							href="/application/cart"
							class="mobile-menu__item"
						>
							Корзина
						</a>
						<a
							v-if="!isMobile"
							href="/about/"
							class="mobile-menu__item"
						>
							О компании
						</a>
						<a
							v-if="!isMobile"
							href="/about/delivery/"
							class="mobile-menu__item"
						>
							Доставка и оплата
						</a>
						<a
							v-if="!isMobile"
							href="/about/bonus/"
							class="mobile-menu__item"
						>
							Скидки и баллы
						</a>
						<a
							v-if="!isMobile"
							href="/about/howto/"
							class="mobile-menu__item"
						>
							Как сделать заказ
						</a>
						<a
							v-if="!isMobile"
							href="/about/delivery/"
							class="mobile-menu__item"
						>
							Отзывы клиентов
						</a>
						<router-link
							v-if="!currentBasket?.collective"
							to="/coverage"
							class="mobile-menu__item"
							@click.native="closeMobileMenu"
						>
							География покрытия
						</router-link>
						<a
							v-if="!isMobile"
							href="/policies/"
							class="mobile-menu__item"
						>
							Юридические документы
						</a>
					</div>
					<div class="mobile-menu__bottom">
						<AddToHomeScreen/>
						<div class="mobile-menu-contacts mobile-menu__item">
							<div
								class="mobile-menu-contacts__icon b24-web-form-popup-btn-5"
								v-if="returnPhone"
							>
								<svg
									width="40"
									height="40"
								>
									<use xlink:href="#header-call-icon"></use>
								</svg>
							</div>
							<div class="mobile-menu__phone">
								<div class="mobile-menu__make-call-wr">
									<div
										class="mobile-menu__number b24-web-form-popup-btn-5"
										v-if="returnPhone"
									>
										{{ returnPhone }}
									</div>
								</div>
							</div>
						</div>
						<div class="mobile-menu-contacts mobile-menu__item">
							<div class="mobile-menu-contacts__icon">
								<svg
									width="40"
									height="40"
								>
									<use xlink:href="#header-call-icon"></use>
								</svg>
							</div>
							<div class="mobile-menu__phone">
								<div class="mobile-menu__number">
									<a href="tel:+78007754131">
										+7 (800) 775-41-31
									</a>
								</div>
							</div>
						</div>
						<div class="mobile-menu-social">
							<h3 class="mobile-menu-social__title">Мы в соц. медиа</h3>
							<div class="mobile-menu-social__links">
								<a
									href="#"
									class="mobile-menu-social__link"
								>
									<img
										src="~@/assets/img/Personal/shareIcons/telegramm.svg"
										alt="telegramm"
									>
								</a>
								<a
									href="#"
									class="mobile-menu-social__link"
								>
									<img
										src="~@/assets/img/Personal/shareIcons/ok.svg"
										alt="ok"
									>
								</a>
								<a
									href="#"
									class="mobile-menu-social__link"
								>
									<img
										src="~@/assets/img/Personal/shareIcons/vk.svg"
										alt="vk"
									>
								</a>
								<a
									href="#"
									class="mobile-menu-social__link"
								>
									<img
										src="~@/assets/img/Personal/shareIcons/twitter.svg"
										alt="twitter"
									>
								</a>
							</div>
						</div>
						<div class="mobile-menu__make-call-wr">
							<button class="mobile-menu__btn b24-web-form-popup-btn-5">Заказать звонок</button>
						</div>
						<div class="mobile-menu__copyright">© АО «Вкусно и Быстро», 2017-{{
								(new Date()).getFullYear()
							}}
						</div>
						<p class="mobile-menu__description">
							Любое использование материалов сайта без разрешения запрещено.
						</p>
					</div>
				</div>
			</div>
			<BottomNavigation/>
		</div>
	</transition>
</template>

<script>
import b24form from "@/mixins/b24form.js";
import {mapGetters, mapMutations} from "vuex";
import CitySelect from '@/components/popup/CitySelect.vue'
import BottomNavigation from "@/components/nav/BottomNavigation";
import AddToHomeScreen from "@/components/block/AddToHomeScreen";

export default {
	name: "MobileMenu",
	mixins: [b24form],
	components:
		{
			BottomNavigation,
			CitySelect,
			AddToHomeScreen,
		},
	data()
	{
		return {
			isMobile: !!process.env.VUE_APP_MOBILE,
			screenSize: window.screen.width,
			body: null,
			isActiveCitySelect: false
		};
	},
	methods:
		{
			...mapMutations({
				setNavigationPopup: "setNavigationPopup",
			}),
			openCitySelect()
			{
				if (this.currentBasket?.collective) return;

				this.isActiveCitySelect = true;
			},
			closeCitySelect(value)
			{
				this.isActiveCitySelect = value;
			},
			closeMobileMenu()
			{
				this.$store.state.mobMenuState && this.$store.commit("hideMobMenu");
			},
			/**
			 ** transitions hooks
			 **/
			enter(element)
			{
				const mobileMenu = document.querySelector(".header__nav-mobile");

				(this.screenSize > 567 && this.screenSize < 990) && (mobileMenu.style.marginLeft = "-50%");

				(this.screenSize < 567) && (mobileMenu.style.marginLeft = "-100%");


				setTimeout(() =>
				{
					element.style.marginRight = "0";
				});
			},

			/**
			 ** transitions hooks
			 **/
			leave(element)
			{
				element.style.opacity = 0;
				element.style.width = 0;
				document.querySelector("#app").style.marginLeft = "0";
				if (document.querySelector(".cart-sidebar")) document.querySelector(".cart-sidebar").style.marginLeft = "0";
			},

			/**
			 ** transitions hooks
			 **/
			afterLeave(element)
			{
				for (let container of document.querySelectorAll(".container"))
					container.style.minWidth = "unset";

				var appSelector = document.querySelector("#app");

				appSelector.style.maxWidth = "unset";
				appSelector.style.boxShadow = "unset";
				appSelector.style.overflowX = "unset";
				appSelector.style.minHeight = "unset";
			},
			// открыть окно авторизации
			authOpen()
			{
				this.hideMobMenu();
				this.$store.commit("openPhoneAuthPopup");
			},
			// открыть меню авторизованного пользователя
			userMenuOpen()
			{
				this.hideMobMenu();
				this.setNavigationPopup(true);
			},
			// выйти
			logOut()
			{
				this.$store.dispatch("logout");
			},
			/**
			 * скрыть мобильное меню
			 */
			hideMobMenu()
			{
				this.$store.commit("hideMobMenu");
			},
			/**
			 * переходит к ссылке
			 */
			linkTo(link)
			{
				this.hideMobMenu();
				if (this.$route.path != link) this.$router.push(link);
			},
		},
	computed: {
		...mapGetters({
			currentBasket: "getCurrentBasket",
			user: "getUser",
		}),
		firstNameLetter: function ()
		{
			return this.user.NAME.split("")[0];
		},
		/**
		 * возвращает телефон
		 */
		returnPhone()
		{
			return this.$store.state.currentCityPhone;
		},
	},

	updated()
	{
		this.addB24FormOnBtn("mobile-menu__make-call-wr");
	},
};

</script>

<style lang="scss">
.mobile-menu-social
{
	display: flex;
	flex-direction: column;
	margin-bottom: 24px;
	gap: 8px;
}

.mobile-menu-social__title
{
	opacity: 0.6;
	font-family: $mainFontNew;
	font-weight: 400;
	font-size: 16px;
	line-height: 150%;
	color: $textColor;
}

.mobile-menu-social__links
{
	display: flex;
	align-items: center;
	gap: 12px;
}

.mobile-menu-social__link
{
	width: 50px;
	height: 50px;

	img
	{
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
}

.mobile-menu-anim
{
	transition: all .2s;
	margin-right: -50%;
}

@media (max-width: 567px)
{
	.mobile-menu-anim
	{margin-right: -100%;}
}

.mobile-menu
{
	position: relative;
	max-height: 100vh;
	min-height: 100vh;
	overflow: scroll;
	scroll-behavior: smooth;
	padding: 32px;
	background: #fff;
	max-width: 100%;

	.scores__amount
	{color: white;}

	.scores__icon
	{color: $textColor;}

	.header__bottom-photo
	{padding: 6px;}

	.header__bottom-user
	{border-radius: 50%;}

	.header-main__bottom-user_placeholder
	{border-radius: 50%;}

	.mobile-menu__item
	{
		padding-bottom: 16px;
		border-bottom: 1px solid $greyDelimiter;
	}

	&__logo
	{
		svg
		{
			width: 175px;
			height: 58px;
		}
	}

	&__background
	{
		backdrop-filter: blur(2px) brightness(80%);
		height: 100%;
		width: 100%;
		animation: mobileMenuBackground .3s;
	}

	@keyframes mobileMenuBackground
	{
		0%
		{opacity: 0;}

		50%
		{opacity: 0;}

		100%
		{opacity: 1;}
	}

	&-city
	{
		font-family: $mainFontMediumNew;
		line-height: 17px;
		text-transform: inherit;
		flex-direction: column;

		&__static
		{
			width: 100%;
			display: flex;
			justify-content: space-between;
			align-items: center;
			cursor: pointer;
		}

		&__label
		{
			font-size: 16px;
			font-weight: bold;
			color: $textColor;
		}

		&__toggle
		{
			&-open
			{margin-top: 20px;}
		}

		&__value
		{
			font-family: $mainFontMediumNew;
			font-size: 16px;
			font-weight: 500;
			color: $green;
			margin-right: 32px;
		}

		&__row
		{
			width: 100%;
			display: flex;
			justify-content: space-between;
		}
	}

	&__copyright
	{
		font-family: $mainFontNew;
		line-height: 16px;
		font-size: 14px;
		color: $greyLightText;
		text-align: center;
		margin: 17px 0 8px 0;
	}

	&__btn
	{
		background-color: rgba($green, .15);
		color: $green;
		border: none;
		font-family: $mainFontBoldNew;
		font-weight: 700;
		font-size: 16px;
		height: 56px;
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 18px 0;
		width: 100%;
		margin: 0 auto;
		border-radius: 8px;
	}

	&__description
	{
		font-family: $mainFontNew;
		font-size: 12px;
		color: $greyLightText;
		line-height: 16px;
		text-align: center;
	}

	&__item
	{
		margin-bottom: 16px;
		justify-content: flex-start;
		display: flex;
		font-family: $mainFontBoldNew;
		font-weight: 700;
		color: $textColor;
		font-size: 16px;
		line-height: 24px;
		position: relative;

		&:before, &:after
		{
			content: '';
			position: absolute;
			right: 8px;
			top: 50%;
			width: 7px;
			height: 2px;
			background: $green;
			border-radius: 2px;
			display: block;
		}

		&:before
		{transform: rotate(45deg) translate(-9px, -7px);}

		&:after
		{transform: rotate(-45deg) translate(4px, -6px);}

		&_btn
		{margin-bottom: 22px !important;}

		&.mobile-menu-contacts
		{
			display: flex;
			align-items: center;
			border: none;
			padding-bottom: 0;
			margin-bottom: 24px;

			&:before, &:after
			{display: none;}
		}

		&_active
		{
			font-size: 16px !important;
			border-radius: 8px;
			padding: 18px 0;
			justify-content: center;
		}

		&_sign-in
		{
			height: 56px;
			display: flex;
			align-items: center;
			margin-bottom: 20px;
		}
	}

	&-contacts
	{
		border-bottom: none;
		padding-bottom: 0;
	}

	&__close
	{
		position: absolute;
		top: 32px;
		right: 30px;
		height: 32px;
		width: 32px;
	}
}

.mobile-menu-anim-enter-to
{width: 100vw;}

.mobile-menu-anim-leave-active
{opacity: 0;}

.mobile-menu-anim-leave-to
{width: 0;}

#app
{transition: all .2s;}

.mobile-menu-wr
{position: fixed;}

.header__nav-mobile
{
	overflow-y: auto;
	position: absolute;
	display: flex;
	top: 0;
	right: 0;
	min-height: 100vh;
	width: 100vw;
	max-width: 100vw;
	z-index: 2000;
}

.mobile-menu-overlay
{
	background: transparent;
	width: 100vw;
	height: 100vh;
	position: absolute;
	top: 0;
	left: 0;
	box-shadow: 4px 0px 10px rgba(0, 0, 0, 0.15);

	.header__nav-mobile_open
	{display: flex;}
}

.mobile-menu-wr
{
	width: 100vw;
	z-index: 10;
}

.mobile-menu
{
	display: flex;
	flex-direction: column;
	padding-bottom: 30px;
}

.mobile-menu__item_map
{
	text-transform: inherit;
	color: $green;
	font-family: $mainFontMediumNew;
	font-size: 13px;
	letter-spacing: 0.55px;
}

.mobile-menu__item_about
{padding-bottom: 4px;}

.mobile-menu__item_sign-in,
.mobile-menu__item_about
{color: $green;}

.mobile-menu__item_sign-in
{margin-top: 40px;}

.mobile-menu__item_active
{
	font-size: 13px;
	color: #fff;
	background-color: $green;
}

.mobile-menu-contacts__icon
{
	line-height: 0;
	margin-right: 12px;
}

.mobile-menu__number
{
	font-family: $mainFontBoldNew;
	font-size: 18px;
	line-height: 24px;
	color: $textColor;
	letter-spacing: .01px;
}

@media (max-width: 990px)
{
	.mobile-menu
	{
		padding: 24px 44px 72px 44px;

		&__item
		{
			&_sign-in
			{
				margin-top: 0;
				margin-bottom: 0;
			}
		}
	}

	.mobile-menu .header
	{
		&__bottom
		{
			&-photo
			{
				&-wrapper
				{
					display: flex;
					padding: 10px 0;
					border-bottom: 1px solid #F0F0F0;
					margin-bottom: 16px;
				}

				height: 56px;
				border: 2px solid #E5E5E7;
				border-radius: 50%;
				flex: 0 0 56px;
				overflow: hidden;

				& img
				{
					width: 100%;
					height: 100%;
				}
			}
		}

		&__logo
		{margin-bottom: 20px;}
	}
}

@media (max-width: 767px)
{
	.mobile-menu
	{
		& .header
		{
			&__logo
			{margin-bottom: 20px;}
		}
	}
}

@media (max-width: 567px)
{
	.mobile-menu
	{
		padding: 16px 32px 72px 32px;

		&__background
		{display: none;}

		& .header
		{
			&__logo
			{
				display: block;
				margin-right: 0;
				width: 175px;
				height: 58px;
				max-width: none;
				min-width: 0;
			}
		}
	}
}

@media (max-width: 374px)
{
	.mobile-menu
	{
		padding: 16px 24px;
		padding-top: 8px;

		&__close
		{
			right: 16px;
			top: 20px;

			img
			{
				width: 24px;
				height: 24px;
			}
		}

		& .header
		{
			&__logo
			{
				margin-bottom: 16px;
				width: 138px;
				height: 46px;
			}
		}

		&__item
		{
			font-size: 14px;
			line-height: 20px;
			margin-bottom: 12px;
			padding-bottom: 14px;

			&_active
			{
				font-size: 14px !important;
				line-height: 20px;
				margin-bottom: 16px !important;
			}

			&_btn
			{
				height: 48px;
				font-size: 14px;
				line-height: 20px;
				margin-bottom: 16px !important;
			}
		}

		&__item::after, &__item::before
		{margin-top: -10px;}

		&__btn
		{
			height: 48px;
			font-size: 14px;
			line-height: 20px;
		}

		&-city
		{
			&__label
			{
				font-size: 14px;
				line-height: 20px;
			}

			&__value
			{
				font-size: 14px;
				line-height: 20px;
				margin-right: 40px;
			}
		}

		&__bottom
		{margin-top: 12px;}

		&__copyright
		{margin: 12px 0 8px 0;}
	}
}
</style>
